import * as React from 'react';

export interface SwitchProps {
    className?: string;
    children?: React.ReactNode;
    checked?: boolean;
    onChange: (checked: boolean) => void;
    disabled?: boolean;
}

export function Switch({ children, checked, onChange, disabled }: SwitchProps) {
    return (
        <label>
            <input
                type="checkbox"
                checked={!!checked}
                onChange={(e) => onChange(e.target.checked)}
                disabled={!!disabled}
            />{' '}
            {children}
        </label>
    );
}
