import * as React from 'react';
import { LoadingState, axiosRequest, API_URL } from './api';
import {
    PropertyListModel,
    PropertyItemModel,
    AccountingSchemaModel,
} from './api.dto';
import { useToken } from './useToken';
import { saveAs } from 'file-saver';
import { AxiosRequestConfig } from 'axios';
import { formatISO, format } from 'date-fns';

export interface IPropertyContext {
    properties: PropertyItemModel[];
}

export const PropertyContext = React.createContext<IPropertyContext>(
    {} as IPropertyContext,
);

export type ExportType =
    | 'bfs'
    | 'sidap'
    | 'tessin'
    | 'nw'
    | 'tourx'
    | 'motaubh'
    | 'vaudriviera'
    | 'bexio-accounting';

export function useProperties() {
    const { token } = useToken();
    const { request, response } = useRequest<PropertyListModel>();
    React.useEffect(() => {
        const config: AxiosRequestConfig = {
            url: `${API_URL}/apaleo/report/properties`,
            method: 'GET',
            responseType: 'json',
        };
        request(token, config);
    }, [token, request]);
    return response;
}

export function useBfsReportRequest(
    propertyName: string,
    year: number,
    month: number,
) {
    const { token } = useToken();
    const { request, response } = useRequest<Blob>();
    React.useEffect(() => {
        if (response.state === 'ready' && response.data) {
            const contentType = response.data.type;
            let ext: string;
            if (contentType === 'application/json') {
                ext = '.json';
            } else if (contentType === 'text/xml') {
                ext = '.xml';
            } else if (contentType === 'text/plain') {
                ext = '.txt';
            } else if (
                contentType ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ) {
                ext = '.xlsx';
            } else {
                ext = '.txt';
            }
            saveAs(response.data, `${propertyName}-${year}-${month}${ext}`);
        }
    }, [response, propertyName, year, month]);
    const getReport = React.useCallback(() => {
        const config: AxiosRequestConfig = {
            url: `${API_URL}/apaleo/report/bfs/${propertyName}/${year}/${month}`,
            method: 'GET',
            responseType: 'blob',
        };
        request(token, config);
    }, [token, request, propertyName, year, month]);
    return { getReport, ...response };
}

export interface BfsSettings {
    enabled?: boolean;
    bfs_id: string;
    num_rooms?: number;
    num_beds?: number;
    contact_name: string;
    contact_phone: string;
    contact_email: string;
    enable_emails: boolean;
    email_to: string;
    email_cc: string;
    format?: string;
}

export function useExportSettings<ExportSettings>(
    exportType: ExportType,
    property_id: string,
) {
    const { token } = useToken();
    const { request, response } = useRequest<ExportSettings>();
    React.useEffect(() => {
        const config: AxiosRequestConfig = {
            url: `${API_URL}/apaleo/report/${exportType}/${property_id}/settings`,
            method: 'GET',
            responseType: 'json',
        };
        request(token, config);
    }, [request, token, property_id, exportType]);
    return response;
}

export function usePatchExportSettings<ExportSettings>(
    exportType: ExportType,
    property_id: string,
) {
    const { token } = useToken();
    const { request, response } = useRequest<ExportSettings>();
    const patch = React.useCallback(
        (inData: any) => {
            const config: AxiosRequestConfig = {
                url: `${API_URL}/apaleo/report/${exportType}/${property_id}/settings`,
                method: 'PATCH',
                responseType: 'json',
            };
            request(token, config, inData);
        },
        [request, token, property_id, exportType],
    );
    return { patch, ...response };
}

interface ProtocolItem {
    _id: string;
    started: string;
    topic: string;
    exitState: string;
}

interface ProtocolList {
    page: number;
    result: ProtocolItem[];
    count: string;
}

export function useHistory(
    page: number = 1,
    perPage: number = 10,
    topicFilter: string = 'bfs-report',
) {
    const { token } = useToken();
    const { request, response } = useRequest<ProtocolList>();
    React.useEffect(() => {
        const params = new URLSearchParams({
            page: '' + page,
            resultsPerPage: '' + perPage,
            sortField: 'started',
            sortOrder: 'desc',
            topicFilter,
        }).toString();
        const config: AxiosRequestConfig = {
            url: `${API_URL}/ui/user/protocol?${params}`,
            method: 'GET',
            responseType: 'json',
        };
        request(token, config);
    }, [request, token, page, perPage, topicFilter]);
    return response;
}

export interface SidapSettings {
    enabled?: boolean;
    client_key: string;
}

export function useSidapReportRequest(propertyName: string) {
    const { token } = useToken();
    const { request, response } = useRequest<Blob>();
    React.useEffect(() => {
        if (response.state === 'ready' && response.data) {
            saveAs(response.data, `${propertyName}.csv`);
        }
    }, [response, propertyName]);
    const getReport = React.useCallback(
        (date: Date | null) => {
            date = date ? date : new Date();
            const dateISO = formatISO(date, { representation: 'date' });
            const config: AxiosRequestConfig = {
                url: `${API_URL}/apaleo/report/sidap/${propertyName}/${dateISO}`,
                method: 'GET',
                responseType: 'blob',
            };
            request(token, config);
        },
        [request, token, propertyName],
    );
    return { getReport, ...response };
}

interface ResponseStatus<Response> {
    data?: Response;
    state: LoadingState;
    error: number;
}

interface Request<Response> {
    request: (
        token: string | null,
        config: AxiosRequestConfig,
        inData?: any,
    ) => void;
    response: ResponseStatus<Response>;
}

function useRequest<Response>(): Request<Response> {
    const [response, setResponse] = React.useState<ResponseStatus<Response>>({
        state: 'initial',
        error: 0,
    });
    const request = React.useCallback(
        (token: string | null, config: AxiosRequestConfig, inData?: any) => {
            const fetchData = async () => {
                setResponse((r) => ({
                    ...r,
                    state: 'loading',
                    error: 0,
                }));
                try {
                    const nConfig = { ...config };
                    nConfig.headers = {
                        ...(nConfig.headers ? nConfig.headers : {}),
                        Authorization: `Bearer ${token}`,
                    };
                    if (inData) {
                        nConfig.data = JSON.stringify(inData);
                        nConfig.headers['Content-Type'] = 'application/json';
                    }
                    const data = await axiosRequest<Response>(nConfig);
                    setResponse((r) => ({
                        ...r,
                        data,
                        state: 'ready',
                        error: 0,
                    }));
                } catch (err: any) {
                    console.log(err);
                    setResponse((r) => ({
                        ...r,
                        state: 'error',
                        error: err.response ? +err.response.status : 0,
                    }));
                }
            };
            fetchData();
        },
        [],
    );
    return { request, response };
}

export interface UserInfo {
    language?: string;
    privacyPolicies?: PrivacyPolicy[];
    termsAndConditions?: TermsAndConditions[];
}

export interface PrivacyPolicy {
    version: string;
    confirmed: string | null;
}

export interface TermsAndConditions {
    version: string;
    confirmed: string | null;
}

export function useGetUserInfo() {
    const { token } = useToken();
    const { request, response } = useRequest<UserInfo>();
    React.useEffect(() => {
        const config: AxiosRequestConfig = {
            url: `${API_URL}/ui/user/info`,
            method: 'GET',
            responseType: 'json',
        };
        request(token, config);
    }, [request, token]);
    return response;
}

export function usePutUserInfo() {
    const { token } = useToken();
    const { request, response } = useRequest<UserInfo>();
    const patch = React.useCallback(
        (inData: any) => {
            const config: AxiosRequestConfig = {
                url: `${API_URL}/ui/user/info`,
                method: 'PUT',
                responseType: 'json',
            };
            request(token, config, inData);
        },
        [request, token],
    );
    return { patch, ...response };
}

export interface IUserInfoContext extends UserInfo {
    state: LoadingState;
    error: number;
    onUpdate: (inData?: any) => void;
}

export const UserInfoContext = React.createContext<IUserInfoContext>(
    {} as IUserInfoContext,
);

export interface SubscriptionStatus {
    subscription?: boolean;
    quantity: number;
    status: string;
}

export function useGetSubscription(exportType: ExportType) {
    const { token } = useToken();
    const { request, response } = useRequest<SubscriptionStatus>();
    React.useEffect(() => {
        const config: AxiosRequestConfig = {
            url: `${API_URL}/apaleo/subscription/${exportType}`,
            method: 'GET',
            responseType: 'json',
        };
        request(token, config);
    }, [request, token, exportType]);
    return response;
}

export interface ResponseUrl {
    url: string;
}

export function usePostSubscriptionCheckout(exportType: ExportType) {
    const { token } = useToken();
    const { request, response } = useRequest<ResponseUrl>();
    const post = React.useCallback(
        (inData: any) => {
            const config: AxiosRequestConfig = {
                url: `${API_URL}/apaleo/subscription/${exportType}/checkout`,
                method: 'POST',
                responseType: 'json',
            };
            request(token, config, inData);
        },
        [request, token, exportType],
    );
    return { post, ...response };
}

export function usePostSubscriptionPortal() {
    const { token } = useToken();
    const { request, response } = useRequest<ResponseUrl>();
    const post = React.useCallback(
        (inData: any) => {
            const config: AxiosRequestConfig = {
                url: `${API_URL}/apaleo/subscription/portal`,
                method: 'POST',
                responseType: 'json',
            };
            request(token, config, inData);
        },
        [request, token],
    );
    return { post, ...response };
}

export interface SubscriptionStatusAll {
    sidap: SubscriptionStatus;
    bfs: SubscriptionStatus;
    tessin: SubscriptionStatus;
    nw: SubscriptionStatus;
    tourx: SubscriptionStatus;
}

export function useGetSubscriptionStatus() {
    const { token } = useToken();
    const { request, response } = useRequest<SubscriptionStatusAll>();
    React.useEffect(() => {
        const config: AxiosRequestConfig = {
            url: `${API_URL}/apaleo/subscription`,
            method: 'GET',
            responseType: 'json',
        };
        request(token, config);
    }, [request, token]);
    return response;
}

export interface ISubscription extends SubscriptionStatusAll {
    state: LoadingState;
    error: number;
    bfsCheckoutRedirect: () => void;
    sidapCheckoutRedirect: () => void;
    tessinCheckoutRedirect: () => void;
    nwCheckoutRedirect: () => void;
    tourxCheckoutRedirect: () => void;
    portalRedirect: () => void;
}

export const SubscriptionContext = React.createContext<ISubscription>(
    {} as ISubscription,
);

export interface TessinSettings {
    enabled?: boolean;
    tessin_id: string;
    enable_emails: boolean;
    email_to: string;
}

export function useTessinReportRequest(propertyName: string) {
    const { token } = useToken();
    const { request, response } = useRequest<Blob>();
    React.useEffect(() => {
        if (response.state === 'ready' && response.data) {
            saveAs(response.data, `${propertyName}.csv`);
        }
    }, [response, propertyName]);
    const getReport = React.useCallback(
        (date: Date | null) => {
            date = date ? date : new Date();
            const dateISO = formatISO(date, { representation: 'date' });
            const config: AxiosRequestConfig = {
                url: `${API_URL}/apaleo/report/tessin/${propertyName}/${dateISO}`,
                method: 'GET',
                responseType: 'blob',
            };
            request(token, config);
        },
        [request, token, propertyName],
    );
    return { getReport, ...response };
}

export interface NwSettings {
    enabled?: boolean;
    nw_id: string;
    enable_sftp: boolean;
    username: string;
    password: string;
}

export function useNwReportRequest(propertyName: string) {
    const { token } = useToken();
    const { request, response } = useRequest<Blob>();
    React.useEffect(() => {
        if (response.state === 'ready' && response.data) {
            saveAs(response.data, `${propertyName}.csv`);
        }
    }, [response, propertyName]);
    const getReport = React.useCallback(
        (date: Date | null) => {
            date = date ? date : new Date();
            const dateISO = formatISO(date, { representation: 'date' });
            const config: AxiosRequestConfig = {
                url: `${API_URL}/apaleo/report/nw/${propertyName}/${dateISO}`,
                method: 'GET',
                responseType: 'blob',
            };
            request(token, config);
        },
        [request, token, propertyName],
    );
    return { getReport, ...response };
}

export interface TourxSettings {
    enabled?: boolean;
    enable_api: boolean;
    api_key: string;
    api_user: string;
    days_advance?: number;
}

export function useTourxReportRequest(propertyName: string) {
    const { token } = useToken();
    const { request, response } = useRequest<Blob>();
    React.useEffect(() => {
        if (response.state === 'ready' && response.data) {
            saveAs(response.data, `${propertyName}.json`);
        }
    }, [response, propertyName]);
    const getReport = React.useCallback(
        (date: Date | null) => {
            date = date ? date : new Date();
            const dateISO = formatISO(date, { representation: 'date' });
            const config: AxiosRequestConfig = {
                url: `${API_URL}/apaleo/report/tourx/${propertyName}/download/${dateISO}`,
                method: 'GET',
                responseType: 'blob',
            };
            request(token, config);
        },
        [request, token, propertyName],
    );
    return { getReport, ...response };
}

interface TourxStatus {}

export function useTourxApiRequest(propertyName: string) {
    const { token } = useToken();
    const { request, response } = useRequest<TourxStatus>();
    const callApi = React.useCallback(() => {
        const config: AxiosRequestConfig = {
            url: `${API_URL}/apaleo/report/tourx/${propertyName}/api`,
            method: 'POST',
            responseType: 'json',
        };
        request(token, config);
    }, [request, token, propertyName]);
    return { callApi, ...response };
}

export interface BexioAccountingSettings {
    enabled?: boolean;
    mapping?: { key: string; value: string }[];
    defaultDebitAccount?: string;
    defaultCreditAccount?: string;
}

export interface BexioConnectionTest {
    status: string;
}

export function useBexioConnectionTest() {
    const { token } = useToken();
    const { request, response } = useRequest<BexioConnectionTest>();
    React.useEffect(() => {
        const config: AxiosRequestConfig = {
            url: `${API_URL}/ui/bexio/connection/test`,
            method: 'GET',
            responseType: 'json',
        };
        request(token, config);
    }, [request, token]);
    return response;
}

export function useAccountingSchema(
    propertyName: string | null,
): ResponseStatus<AccountingSchemaModel> {
    const { token } = useToken();
    const { request, response } = useRequest<AccountingSchemaModel>();
    React.useEffect(() => {
        if (propertyName) {
            const config: AxiosRequestConfig = {
                url: `${API_URL}/apaleo/accounting/${propertyName}/schema`,
                method: 'GET',
                responseType: 'json',
            };
            request(token, config);
        }
    }, [request, token, propertyName]);
    return response;
}

export interface BexioAccount {
    id: number;
    account_no: string;
    name: string;
    account_group_id: number;
    account_type: number;
    tax_id: number;
    is_active: boolean;
    is_locked: boolean;
    type: string;
}

export function useBexioAccountingSchema(): ResponseStatus<BexioAccount[]> {
    const { token } = useToken();
    const { request, response } = useRequest<BexioAccount[]>();
    React.useEffect(() => {
        const config: AxiosRequestConfig = {
            url: `${API_URL}/ui/bexio/accounts`,
            method: 'GET',
            responseType: 'json',
        };
        request(token, config);
    }, [request, token]);
    return response;
}

export interface MotauBHSettings {
    enabled?: boolean;
    motaubh_id: string;
    enable_sftp: boolean;
    username: string;
    password: string;
}

export function useMotauBHReportRequest(
    propertyName: string,
    motaubh_id: string,
) {
    const { token } = useToken();
    const { request, response } = useRequest<Blob>();
    React.useEffect(() => {
        if (response.state === 'ready' && response.data) {
            const exportDate = format(new Date(), 'yyMMddhhmm');
            const filename = `${motaubh_id}_${exportDate}.txt`;
            saveAs(response.data, filename);
        }
    }, [response, propertyName, motaubh_id]);
    const getReport = React.useCallback(
        (date: Date | null) => {
            date = date ? date : new Date();
            const dateISO = formatISO(date, { representation: 'date' });
            const config: AxiosRequestConfig = {
                url: `${API_URL}/apaleo/report/motaubh/${propertyName}/${dateISO}`,
                method: 'GET',
                responseType: 'blob',
            };
            request(token, config);
        },
        [request, token, propertyName],
    );
    return { getReport, ...response };
}

export interface VaudRivieraSettings {
    enabled?: boolean;
    vaudriviera_id: string;
    enable_emails: boolean;
    email_to: string;
}

export function useVaudRivieraReportRequest(
    propertyName: string,
    vaudriviera_id: string,
) {
    const { token } = useToken();
    const { request, response } = useRequest<Blob>();
    React.useEffect(() => {
        if (response.state === 'ready' && response.data) {
            const exportDate = format(new Date(), 'ddMMyyyy');
            const filename = `${vaudriviera_id}_${exportDate}.xlsx`;
            saveAs(response.data, filename);
        }
    }, [response, propertyName, vaudriviera_id]);
    const getReport = React.useCallback(
        (date: Date | null) => {
            date = date ? date : new Date();
            const dateISO = formatISO(date, { representation: 'date' });
            const config: AxiosRequestConfig = {
                url: `${API_URL}/apaleo/report/vaudriviera/${propertyName}/${dateISO}`,
                method: 'GET',
                responseType: 'blob',
            };
            request(token, config);
        },
        [request, token, propertyName],
    );
    return { getReport, ...response };
}
