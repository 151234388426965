import * as React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Base, PageParams } from './Base';
import { PropertyContext } from '../api';
import { NotFound } from './NotFound';

interface ExportBadgeProps {
    url: string;
    name: string;
}

function ExportBadge({ url, name }: ExportBadgeProps): JSX.Element {
    return (
        <div className="border-1 border-black shadow-lg w-40 h-40 p-2 bg-white m-2 rounded flex flex-col">
            <span className="font-bold">{name}</span>
            <div className="flex-grow" />
            <div className="text-center p-2">
                <Link to={url} className="border border-black px-4 py-2">
                    Details
                </Link>
            </div>
        </div>
    );
}

export function PropertyDetails(): JSX.Element {
    const { properties } = React.useContext(PropertyContext);
    const { id } = useParams<PageParams>();
    const property = properties.find((p) => p.code === id);
    if (!property) {
        return <NotFound />;
    }

    return (
        <Base>
            <h1 className="font-bold text-3xl">{property.name}</h1>
            <div className="flex flex-row flex-wrap">
                <ExportBadge
                    url={`/properties/${id}/bfs-export`}
                    name="BFS Export"
                />
                <ExportBadge
                    url={`/properties/${id}/sidap-export`}
                    name="SIDAP Export"
                />
                <ExportBadge
                    url={`/properties/${id}/tessin-export`}
                    name="Guest Control Tessin"
                />
                <ExportBadge
                    url={`/properties/${id}/nw-export`}
                    name="Guest Control NW"
                />
                <ExportBadge
                    url={`/properties/${id}/tourx-export`}
                    name="TourX"
                />
                <ExportBadge
                    url={`/properties/${id}/bexio-accounting`}
                    name="Bexio Accounting"
                />
                <ExportBadge
                    url={`/properties/${id}/motaubh-export`}
                    name="MotauBH (Geneve)"
                />
                <ExportBadge
                    url={`/properties/${id}/vaudriviera-export`}
                    name="Vaud Riviera"
                />
            </div>
        </Base>
    );
}
