import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import Modal from 'react-modal';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'tw-elements';

const GTM_TOKEN = process.env.REACT_APP_GTM_TOKEN || '';
if (GTM_TOKEN) {
  const tagManagerArgs = {
      gtmId: GTM_TOKEN,
  };
  TagManager.initialize(tagManagerArgs);
}

const appElement = document.getElementById('root');
if (appElement) {
    Modal.setAppElement(appElement);
    ReactDOM.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        appElement
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
